<template lang="pug">
	div.mplAlerts
		pw-alert(v-for='i in alerts' :key='i.text' :variant='i.variant' :btn='i.btn' :text='i.text' :textFn='i.textFn' :badge="i.badge")
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import PwAlert from '@/components/Form/PwAlert';

export default {
    name: 'ProjectAlerts',
    components: {
        PwAlert,
    },
    data: () => ({
        alerts: [],
        ruAcc: null,
    }),
    computed: {
        ...mapGetters({
            item: 'merchantPartner/detail',
            accountListActiveRemoved: 'finance/accountsActiveRemoved',
        }),
        accountListFiltered() {
            return _.sortBy(this.accountListActiveRemoved, a => {
                return a.isRUB() && a.status == 'ACTIVE' ? 0 : 1;
            });
        },
    },
    methods: {
        ...mapActions({
            set: 'merchantPartner/edit',
            getItem: 'merchantPartner/id',
        }),
        setAlerts() {
            this.alerts = [];

            if (!this.item) return this.alerts;

            if (this.me && this.item && !this.item.project.isActive())
                this.alerts.push({
                    variant: 'danger',
                    text: this.$t('merchantPartners.alerts.blocked', { itemTitle: this.item.project.title }),
                    badge: '🚧',
                    btn: null,
                });

            if (this.item.isArchived)
                this.alerts.push({
                    variant: 'info',
                    text: this.$t('merchantPartners.projectArchived'),
                    btn: { fn: this.unarchive, title: this.$t('button.extract') },
                    badge: '🗄️',
                });
        },

        openModalSupport() {
            this.$bvModal.show('modal-support');
        },
        unarchive() {
            const params = {
                id: this.item.id,
                isArchived: false,
            };
            this.set(params).then(i => {
                this.getItem({ id: this.item.id });
                this.$notify('success', this.$t('success.projectActivated'));
            });
        },
    },
    created() {
        this.setAlerts();
    },
    watch: {
        item: {
            handler(nv, ov) {
                this.setAlerts();
            },
            deep: true,
        },
    },
};
</script>
<style scoped lang="scss">
.mplAlerts::v-deep {
    b {
        font-weight: 600;
    }
    .alert.alert-rainbow.alert-info {
        color: #082846 !important;
        font-size: 15px !important;
    }
}
</style>
